<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>转移记录统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                    <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                        日
                        </a-radio-button>
                        <a-radio-button value="week">
                        周
                        </a-radio-button>
                        <a-radio-button value="month">
                        月
                        </a-radio-button>
                        <a-radio-button value="year">
                        年
                        </a-radio-button>
                    </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>时间筛选</span>
                        </template>
                        <a-range-picker
                        :ranges="rangesData"
                        :value='rangesValue'
                        :disabled-date="disabledDate" :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                        @change="(date, dateString)=>onChange(date, dateString,1)" />
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <!-- <a-form-item class="block-line" label="来源渠道">
                                    <a-tree-select
                                        v-model="paramsData.channel_id"
                                        :tree-data="parentChannels"
                                        tree-checkable
                                        allowClear
                                        treeNodeFilterProp="title"
                                        :maxTagCount='1'
                                        :maxTagTextLength='5'
                                        :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                        :show-checked-strategy="SHOW_ALL"
                                        placeholder="请选择来源渠道"
                                    />
                                </a-form-item> -->
                                <a-form-item class="block-line" label="所属校区">
                                  <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                  </a-select>
                                </a-form-item>

                                <a-form-item  class="block-line" label="操作账户">
                                  <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model="paramsData.assigned_by" allowClear showSearch @search="handleSearch" placeholder="请选择操作账户" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <!-- <a-form-item class="block-line" label="跟进事件">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.event_id' allowClear showSearch placeholder="请选择跟进事件" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of eventList" :key="index" :value="d['event_id']">{{ d['event_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item> -->
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                      <transferCount :loading='loading' title="转移记录统计"  :leads_data ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                        <sankey :loading ='loadingS' :link='link' :maxLength='maxLength' :sankey_title="sankey_title"  :sankey_data ='sankey_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="员工转移统计" :rank_data="assigned_by" :loading="loadingF" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    
                    <a-col :lg="24" :xl="12">
                      <LRanking title="校区转移统计" :rank_data="studio_by" :loading="loadingF" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import sankey from './sankey'
import heatmap from './heatmap'
import transferCount from './transfer_count'
import studioFollowCustomer from './studio_follow_customer'
import statistics from '@/views/bi/ranking/money/statistics'


export default {
  name: 'transfer',
  components:{
    sankey,
    heatmap,
    transferCount,
    statistics,
    studioFollowCustomer
  },
  data() {
    return {
      loading:false,
      visible:false,
      loadingF:false,
      loadingS:false,
      loadingFC:false,
      loadingFH:false,
      maxLength:'',
      sankey_data:{},
      sankey_title:'',
      link:{},
      leads_data:{},
      assigned_by:{},
      studio_by:{},
      heatmap_data:{},
      contact_type_data:{},
      studio_follow_data:{},
      follow_channel_data:{},
      follow_sub_channel_data:{},
      course_consultant_follow_data:{},
      counts:[],
      studios:[],
      channelsId:[],
      parentChannels: [],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:undefined,
        filter_type:'day'
      },
      followName:'',
      staffList:[],
      eventList:[]
    }
  },
  mixins: [ranges],
  created() {
    this.followName = this.$route.meta.name
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
    this.getStaff()
    // this.getEventList()
    // this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getTransferAmount(obj)
      this.getSankey(obj)
      // this.getCount(obj)
      // this.getHeatmap(obj)
      // this.getFollowChannel(obj)
      let res = await this.$store.dispatch('analysisCrmTransferAction', obj)
      this.leads_data = res.data

      // let res_rank = await this.$store.dispatch('analysisCrmFollowRankAction', obj);
      // this.studio_follow_data=res_rank.data.studio_follow;
      // this.course_consultant_follow_data=res_rank.data.course_consultant_follow;
      // let res_contact_type= await this.$store.dispatch('analysisCrmFollowContactTypeAction', obj);
      // this.contact_type_data=res_contact_type.data;
      this.loading = false
    },
    async getTransferAmount(obj){
      this.loadingF = true
      let res = await this.$store.dispatch('analysisCrmTransferAmountAction', obj)
      this.assigned_by = res.data.assigned_by
      this.studio_by = res.data.studio
      this.loadingF = false
    },
     async getSankey(obj) {
      this.loadingS = true
      let res = await this.$store.dispatch('analysisCrmTransferSankeyAction', obj)
      this.sankey_data = res.data.rows
      this.sankey_title = res.data.title
      this.link = res.data.link
      let lengthObj = res.data.length
      let arr = [lengthObj.cc,lengthObj.mkt]
      this.maxLength = Math.max(...arr)
      this.loadingS = false
    },
    async getFollowChannel(obj){
      this.loadingFC = true
      let res = await this.$store.dispatch('analysisCrmFollowChannelAction', obj)
      this.follow_channel_data = res.data.channel
      this.follow_sub_channel_data = res.data.sub_channel
      this.loadingFC = false
    },
    async getHeatmap(obj){
      this.loadingFH = true
      let res = await this.$store.dispatch('analysisCrmFollowHeatmapAction', obj)
      this.heatmap_data = res.data
      this.loadingFH = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmFollowCountAction', obj)
      this.counts = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    async getEventList(){
        await this.$store.dispatch('cFollowEventAction', {})
        .then(res=>{
            this.eventList = res.data
        })
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen()
    },

    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj)
    },
    closeSearch() {
      this.visible = false
    },
    reset(){
      this.paramsData.channel_id = undefined
      this.channelsId = []
      this.paramsData.studio_id = undefined
      this.paramsData.created_by = undefined
    },
    handleSearch(val){
        this.getStaff(val)
    },
  }
}
</script>