<template>
   <a-card title="">
        <div style="height:600px;overflow-y: auto;">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-sankey 
                :data="chartData" 
                judge-width
                :settings="chartSettings"
                :height="wHeight"
                    :title="{
                    text:`转移分布桑基图 （${sankey_title?sankey_title:'0'}）`,
                    subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                }" 
                :extend="{
                    series:{
                        type:'sankey',                    
                        left: '5%',
                        top: '10%',
                        right: '10%',
                        bottom: '5%',
                        nodeWidth:20 ,
                        nodeGap: 10,
                        nodeAlign: 'justify',
                        layoutIterations: 0,
                        orient: 'horizontal',
                        draggable:true,
                        focusNodeAdjacency: false,
                        label: {
                            show: true,
                            position: 'right',
                            fontFamily: 'Arial',
                            margin: 8,
                            normal: {
                                fontSize:10.5,
                                formatter: (o)=>{
                                    return this.handleFormatter(o)
                                }
                            },
                        },
                        itemStyle: {
                            borderWidth: 0,
                            borderColor: '#ccc'
                        },                       
                        lineStyle: {
                            show: true,
                            width: 1,
                            opacity: 0.2,
                            curveness: 0.6,
                            type: 'solid',
                            color: 'source',
                        }
                    },
                }"
            ></ve-sankey>
            <LEmpty v-if="first>0 && sankey_data.length == 0"/>
        </div>
    </a-card>
</template>

<script>
    export default {
        name:"sankey",
        data() {
            this.chartSettings = {
                links: []
            }
            return {
                chartData: {
                    columns: ['name','value'],
                    rows: []
                },
                first:0
            }
        },
        props:['loading' , 'sankey_data' , 'link','start_date','end_date','maxLength','sankey_title'],
        methods: {
            handleFormatter(o) {
                return `${o.data.name} (${o.data.value})`
            }
        },
        computed: {
            wHeight() {
                let height = 600
                if(this.maxLength > 20){
                    height = 600 + ( this.maxLength - 20 ) * 10
                }
                return `${height}px`
            }
        },
        watch: {
            link() {
               this.chartSettings.links = this.link
               this.chartData.rows = this.sankey_data
            },
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>